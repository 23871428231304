@basespeed: 135; //: px / second
@bulletspeed: 85 * 3;
@buildingspeed: @basespeed / 5;
@grassSpeed: @basespeed / 2;

@objectSpawnStart: 400;
@objectSpawnEnd: -1000;

@bulletSpawnStart: 90;
@bulletSpawnEnd: 400;

@bulletDuration: ((@bulletSpawnEnd - @bulletSpawnStart) / @bulletspeed * 1000) * 1ms;
@objectDuration: ((@objectSpawnStart - @objectSpawnEnd) / @basespeed * 1000) * 1ms;
@buildingSpeed: ((@objectSpawnStart - @objectSpawnEnd) / @buildingspeed * 1000) * 1ms;

@grassImageWidth: 737;
@grassImageWidthNeg: -737px;

@grassImageHeight: 100px;
@grassDuration: (@grassImageWidth / @grassSpeed * 1000) * 1ms;

@landImageWidth: 737;
@landImageWidthNeg: -737px;
@landImageSpeed: (@landImageWidth / @basespeed * 1000) * 1ms;

@virusSize: 80px;

/* */
@z-flyarea: 3;
@z-ground: 2;

@z-building: 2;
@z-grass: 4;

@z-player: 6;
@z-object: 5;
@z-bullet: 7;

@z-score: 6;





@canvasWidth: 500px;
@ceilWidth: -587px;




@keyframes animLand {
   0% { background-position: 0px 0px; }
   100% { background-position: @landImageWidthNeg 0px; }
}

@keyframes animGrass {
   0% { background-position: 0px 100%; }
   100% { background-position: @grassImageWidthNeg 100%; }
}

@keyframes animBuilding {
   0% { left: @objectSpawnStart * 1px; }
   100% { left: @objectSpawnEnd * 1px; }
}


@keyframes animPipe {
   0% { left: @objectSpawnStart * 1px; }
   100% { left: @objectSpawnEnd * 1px; }
}

@keyframes animVirus {
   0% { transform: scale(1); }
   25% { transform: scale(1.05); }
   50% { transform: scale(1.1); }
   100% { transform: scale(1.05); }
}

@keyframes animKillVirus {
   0% { opacity: 1;}
   100% { opacity: 0;}
}



@keyframes animCeiling {
   0% { background-position: 0px 0px; }
   100% { background-position: @ceilWidth 0px; }
}

#gamecontainer
{
   position: fixed;
   top:0;
   bottom:0;
   left:0;
   right:0;

   display: flex;
   align-items: center;
   flex-flow: column;
   justify-content: center;
   justify-self: center;
   box-sizing: content-box;
   // background: linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);
   overflow: hidden;
   pointer-events: all;
   touch-action: none;
}

.spacer {
   flex-grow: 1;
}

/*
Screen - Game
*/
#gamescreen
{
   // top: -550px;
   position: fixed;


   min-width: 320px;
   max-width: 375px;
   width: 100%;
   /* max-width: 680px; */

   height: 100%;
   min-height: 500px;

   /* max-height: 0%; */

   /* max-height: 1024px; */
   /* overflow: hidden; */

   background-size: cover;

   background-size: 680px;
   /* background-repeat: no-repeat; */
   /* background-color: yellow; */
   /* border: 4px solid green;; */
   display: flex;
   align-items: flex-start;
   box-sizing: border-box;
   justify-content: flex-start;
   flex-flow: column;
   /* border-radius: 10px;; */
   background-color: transparent;
   overflow-x: hidden;
   &.open {
      top: 0px;
   }
}

#ceiling
{
   position: relative;
   /* top: 0; */
   // height: 2px;
   flex-shrink: 0;
   width: 100%;
   overflow: hidden;
   /* background-image: url('../assets/ceiling.png');
   background-repeat: repeat-x; */

   /* -webkit-animation: animCeiling 7481ms linear infinite;
   animation: animCeiling 7481ms linear infinite; */
   pointer-events: none;
   background-color: transparent;
   /* border-bottom: 1px solid black; */
   /* border:5px solid blue; */
   box-sizing: border-box;
   /* border-radius: 10px 10px 0px 0px; */
}

#grass
{
   position: absolute;
   bottom: 0;
   left:0;
   right: 0;
   height: 1000px;

   background-repeat: repeat-x;
   background-size: (@grassImageWidth * 1px);


   animation: animGrass @grassDuration linear infinite;


   z-index: @z-grass;
   touch-action: none;
}

#land
{
   height: 30px;
   width: 737px;
   bottom: 0px;

   background-repeat: repeat-x;
   background-position: 0px 0px;
   background-color: transparent;

   animation: animLand @landImageSpeed linear infinite;
   pointer-events: none;
   // flex-shrink: 0;
   /* border-radius: 0px 0px 10px 10px; */
   // border-top: 1px solid black;
   touch-action: none;
   z-index: @z-ground;
}

#flyarea
{
   position: relative;

   flex-grow: 1;
   overflow: hidden;
   /* position: relative; */
   /* bottom: 0; */
   /* height: 450px; */
   width: 100%;
   /* background-color: red; */
   z-index: @z-flyarea;
   width: @canvasWidth;
   touch-action: none;
}
.building {
   position: absolute;
   left: @objectSpawnEnd * 1px;
   height: 100px;
   width: 100px;
   pointer-events: none;

   z-index: @z-building; //2
   animation: animBuilding @buildingSpeed linear ;
   background-repeat: no-repeat;
   background-size:contain;
   background-position-y: bottom;
   background-position-x: center;
   bottom: 50px;
   transform-origin: left bottom;
}
.building.molen {
   width: 100px;
   height: 100px;;
}
.building.stadion {
   width: 100px;
   height: 35px;;
   // background-image: var(--image-stadion);
}



#bigscore
{
   position: fixed;
   left:0;
   top: 15px;
   pointer-events: none;
   z-index: @z-score;
   width: 100%;
   text-align: center;
   width: 100vw;
   font-size: 36px;
   color:white;

}

#bigscore img
{
   display: inline-block;
   padding: 1px;
}

.splash {
   position: absolute;
   width: 100%;
   height: 100%;
}

.splashcontent
{
   position: absolute;
   width: 100%;
   height: 100%;

   background-position: 50% 50%;
   background-size: 100%;
   z-index: 100;

   // background-size: contain;

   background-repeat: no-repeat;
}

#medal
{
   position: absolute;
   opacity: 0;
   top: 114px;
   left: 32px;
   width: 44px;
   height: 44px;
}



.highscore
{
   color: #54CC39;
   // font-size: 24px;
   // position: absolute;
   // top: 147px;
   // left: 107px;
   // width: 104px;
   // height: 14px;
   // text-align: right;
}

.highscore img
{
   padding-left: 2px;
}

.boundingbox
{
   position: absolute;
   display: none;
   top: 0;
   left: 0;
   width: 0;
   height: 0;
   border: 1px solid red;
}

#player
{
   left: 10px;
   top: 200px;
   /* border: 1px solid red; */
   box-sizing: border-box;
   z-index: @z-player;

   position: absolute;
   background-size: 100%;
   // -webkit-animation: animBird 400ms steps(4) infinite;
   // animation: animBird 400ms steps(4) infinite;
   pointer-events: none;
   /* // border: 1px solid green; */
   &.throw {
      -webkit-animation: none;
      animation: none;
      // background-position: 0px @playerThrowPosition;
      /* animation-play-state: paused;
      -webkit-animation-play-state: paused; */
   }

}

#gamescreen.start {
   transition: top 200ms ease-in;
   #player {
      transition: left 0s 0s  ease-out;;
      left: -150px;
   }
   .splash {
         transform: scale(0);
         transition: transform 1s 0s  ease-out;;

   }
}
#gamescreen {
   transition: top 450ms ease-out;
   #player {

      transition: left 1000ms 750ms ease-out;;
      &.dead {
         transition: transform 700ms ease-out, top 700ms ease-in;
      }
   }
   #splash {
      .splashcontent {
         transform: scale(1);
         transition: transform 100ms 1750ms ease-out;
      }
   }
}




.bullet {
	// background-image: var(--image-toiletrol);
	max-height: 35px;
	max-width: 35px;
	height: 35px;;
   width: 35px;;
   left: (@bulletSpawnEnd * 1px);
	background-repeat: no-repeat;
	background-size: contain;
   z-index: @z-bullet;

   position: absolute;
   /* left: -100px; */
   // width: 52px;
   // height: 52px;
   /* // top: 100px; */
   /* z-index: 10; */
	/* border:1px solid orange; */
   // -webkit-animation: animBullet 3500ms linear;
   /* border: 1px solid red; */
   pointer-events: none;
   /* border: 1px solid black; */
}

.bullet .blocked {
   animation: none;
}

.virus {
   position: absolute;
   z-index: @z-object;
   left: -100px;

   pointer-events: none;

   max-height: @virusSize;
	max-width: @virusSize;
	height: @virusSize;;
   width: @virusSize;;
   // box-sizing: content-box;
   border-radius: 50%;

	// background-image: var(--image-corona);
	background-repeat: no-repeat;
   background-size: contain;


}

.virus.hit {
   // -webkit-animation: animPipe  @objectDuration linear , animKillVirus 100ms linear;;
   // animation: animPipe  @objectDuration linear , animKillVirus 0ms linear;;
   opacity: 0; // fade out
}

.pipe {
   z-index: @z-object;
   position: absolute;
   left: -1000px;
   height: 100%;
   animation: animPipe @objectDuration linear;
   pointer-events: none;
}

.pipe_upper
{
   position: absolute;
   top: 0;
   width: 45px;
   // background-image: var(--image-pijp-boven);
   background-repeat: no-repeat;
   background-position: bottom;
}

/* .pipe_upper:after
{
   content: "";
   position: absolute;
   bottom: 0;
   width: 52px;
   height: 26px;
   background-image: url('../assets/pipe-down.png');
} */

.pipe_lower
{
   position: absolute;
   bottom: 0;
   width: 45px;
   // background-image: var(--image-pijp-beneden);
   background-repeat: no-repeat;
   background-position: top;
}

/* .pipe_lower:after
{
   content: "";
   position: absolute;
   top: 0;
   width: 52px;
   height: 26px;
   background-image: url('../assets/pipe-up.png');
} */

#footer
{
   position: absolute;
   bottom: 3px;
   left: 3px;
}

#footer a,
#footer a:link,
#footer a:visited,
#footer a:hover,
#footer a:active
{
   display: block;
   padding: 2px;
   text-decoration: none;
   color: #fff;
}

.debugbox {
   position: absolute;
   border: 3px solid red;
   box-sizing: border-box;
   z-index: 100;

}
.debugbox.blue {
   border-color: blue;
}
.debugbox.green {
   border-color: green;
}
.debugbox.purple {
   border-color: purple;
}


.currentscore
{
   // color: #54CC39;
   // font-style: normal;
   // font-weight: normal;
   // font-size: 24px;
   // line-height: 24px;
   margin-bottom: 16px;
}

.currentscore img
{
   padding-left: 2px;
}

#gamecontainer {
   &.deadflash {
      #flyarea {
         animation: animDeadFlash 100ms linear;
      }
   }
}

@keyframes animDeadFlash {
   0% { background-image: initial }
   // 19% { background:  linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);; }
   25% { background-image: unset; }
   75% { background-image: unset; }
   //21% { background: white; }
   // 50% { background-image: url(../assets/lucht.png);;; }
   // 75% { background-image: unset; }
   // 59% { background: linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);; }
   // 60% { background: white; }
   // 61% { background: white; }
   // 62% { background: linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);; }

   100% { background-image: initial }
}

.debugPolygon {
   position: absolute;
   top:0;
   left:0;
   width: 100%;
   height: 100%;
   box-sizing: border-box;
   z-index: 3000;
   pointer-events: none;
}